@font-face {
  font-family: 'Architects Daughter';
  src: local('Architects_Daughter'), url('./fonts/Architects_Daughter/ArchitectsDaughter-Regular.ttf')
}

.break-word {
  word-wrap: break-word; /* Old browsers */
  word-break: break-word; /* This is the modern way of applying word wrapping */
  overflow-wrap: break-word; /* Ensures that long words will wrap and not overflow */
}


.gradient-text {
  font-size: 40px;
  font-weight: bold;
  background-image: linear-gradient(to bottom, #4186EC, #0EB7FA);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}


.quiz-title {
  font-family: 'Architects Daughter';
  font-size: 1.5rem;
  font-weight: bold;
  padding: 2rem;
}

.quiz-heading {
  font-size: 1.5rem;
  margin-top: 2rem;
}

.clock {
  font-size: 2.5rem;
}

.correct {
  font-size: .8rem;
  font-family: 'Architects Daughter';
  font-weight: bold;
}


.nav-pills .nav-link {
  color: #222 !important;
  background-color: #fff !important;
  border-color: #222 !important;
}

.nav-pills .nav-link.active {
  color: #fff !important;
  background-color: #222 !important; /* A darker shade of primary color */
  border-color: #fff !important;
}

.custom-placeholder-color::placeholder {
  color: white !important;
}


$theme-colors: (
  'primary': #008080,
  'yellow': #FDE74C,
  'orange': #E55934,
  'blue': #5bc0eb,
  'dark': #222,
  'light': #fff,
  'gray': #a4a9ad,
  'facebook': #3b5998,
  'google': #EA4335,
  'danger': #ff0000,
  'success': #008080
);

$dark: #222;
$light: #fff;


.main-nav {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #a4a9ad; 
}

.sponsor-banner {
  max-width: 100%;
  object-fit: contain;
}

@media (min-width: 576px) {
  .sponsor-banner {
    max-width: 468px;
    max-height: 60px;
  }
  .quiz-title {
    font-size: 2rem;
  }
  .correct {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .quiz-title {
    font-size: 2.5rem;
  }
  .quiz-heading {
    font-size: 2rem;
    margin-top: 4rem;
  }
  .correct {
    font-size: 1.5rem;
  }
}



@import '~bootstrap/scss/bootstrap';